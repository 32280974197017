import Txt from '@primitive/Txt';
import { createBlock } from '@lib/features/blocks/blocks';
import { AccordionItemType } from './AccordionItem';

type AccordionBlockProps = {
  items: AccordionItemType[];
  heading?: string;
  content?: string;
};

export const useAccordionBlock = createBlock<
  'blocks_accordion_BlockType',
  NoProps,
  AccordionBlockProps
>(({ heading, content, children = [] }) => {
  const items = children.reduce((results, child) => {
    const item: AccordionItemType = {
      label: child.label ?? '',
      content: (
        <>
          <Txt html>{child.content}</Txt>
          {/* {subBlocks} */}
        </>
      ),
      // children: subBlocks,
    };

    results.push(item);

    return results;
  }, [] as AccordionItemType[]);

  return {
    heading: heading ?? undefined,
    content: content ?? undefined,
    items,
  };
});
