// import BlockHeader from '@component/BlockHeader';

import Box, { BoxProps } from '@primitive/Box';
import Txt from '@primitive/Txt';
import { useState } from 'react';
import { AccordionItem, AccordionItemType } from './AccordionItem';

export type AccordionProps = BoxProps<
  'div',
  {
    items: AccordionItemType[];
  }
>;
/**
 * Accordion with introduction heading and body copy
 */
const Accordion = ({ items = [], ...props }: AccordionProps) => {
  const [expanded, setExpanded] = useState<number | null>(null);

  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <Box {...props}>
      {items.map(({ label, content }, i) => (
        <AccordionItem
          onChange={handleChange(i)}
          open={expanded === i}
          first={i === 0}
          key={i}
          label={label}
          content={<Txt html>{content}</Txt>}
        />
      ))}
    </Box>
  );
};

Accordion.Item = AccordionItem;
// Accordion.Block = AccordionBlock;

export default Accordion;
