import BlockHeader from '@components/BlockHeader';
import { BlockContainer } from '@components/Blocks';
import { createBlock } from '@lib/features/blocks/blocks';
import Accordion from './Accordion';
import { useAccordionBlock } from './useAccordionBlock';

const AccordionBlock = createBlock<'blocks_accordion_BlockType'>((props) => {
  const { heading, content, items } = useAccordionBlock(props);

  return (
    <BlockContainer maxWidth="md">
      <BlockHeader {...{ heading, content }} />
      <Accordion items={items} />
    </BlockContainer>
  );
});

export default AccordionBlock;
